import { Moment } from 'moment';
import { DateTime } from 'luxon';

export const momentToLocalDateTime = (date: Moment): string => {
  if (!date.isValid()) {
    return '';
  }
  return date.format('YYYY-MM-DDTHH:mm:ss');
};

declare module 'luxon/src/datetime' {
  export interface DateTime {
    toLocalDateTime(): string;
  }
}

DateTime.prototype.toLocalDateTime = function (): string {
  const _self = this as DateTime;
  return _self.toFormat("yyyy-MM-dd'T'HH:mm:ss");
};
